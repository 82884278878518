import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import hash from 'json-stable-stringify';
import IState from 'services/state';
import SubscriptionsLibraryModal from 'components/admin2/SubscriptionsLibraryModal';
import TranslatedText from 'components/i18n/TranslatedText';
import ToggleSwitch from 'components/admin2/ui/ToggleSwitch';
import {
  TooltipInfoIcon,
  AdvancedOptionsWrapper,
  AccessCodeText,
  TicketSubscriptionBundleGateSettingsWrapper,
  NoSubscriptionsText,
  WideButton,
} from './styles';
import { compareToSort } from 'shared/string-utils';
import MediaLibraryTable, { IColumn, IProps as IMediaLibraryTableProps } from 'components/admin2/MediaLibraryTable';
import IGate, { GateKind } from 'models/IGate';
import ISubscription from 'models/ISubscription';
import { getTypeValue, findDefaultPrice } from 'shared/string-utils';
import IBundleModel from 'models/IBundle';
import { isBundle } from 'services/bundle/utils';
import { isFeatureEnabled, Feature } from 'services/feature-gate';
import { useAdminTranslation } from 'hooks/use-translation';
import { getPlanFeatures } from 'services/billing';
import { ModalKinds } from 'services/modals/types';
import { showModal } from 'services/modals';
import { AccessControlGateSettingsHeader } from './AccessControlGateSettingsHeader';

export interface ITicketSubscriptionBundleGateSettings {
  accessCodeEnabled: boolean;
  accessGateStateChanges: IGate;
  handleChangeGateClick: () => void;
  setAccessCodeEnabled: (accessCodeEnabled: boolean) => void;
  setAccessGateStateChanges: (value: IGate) => void;
  setHasGateError: (value: boolean) => void;
  setIsGateOnFinalStep: (value: boolean) => void;
  setVisibilityError: (value: boolean) => void;
  showAdvancedOptions: boolean;
}

interface IBundle extends IBundleModel {
  type: 'bundle';
}

type Item = ISubscription | IBundle;

const NAME_STRING = 'name';
const SKU_STRING = 'sku';
const PRICES_STRING = 'prices';
const TYPE_STRING = 'type';

const ASC_KEY = 'asc';
const DESC_KEY = 'desc';

const parseSubscriptionData = (
  subscriptions: ISubscription[],
  hiddenEntitlements: string[],
) => {
  return subscriptions.map((sub) => ({
    ...sub,
    visible: sub.visible
      ? sub.visible
      : !Boolean(hiddenEntitlements.includes(sub.sku)),
  }));
};

const parseBundleData = (
  bundles: IBundleModel[],
  hiddenBundles: string[],
): IBundle[] => {
  return bundles.map((bundle) => ({
    ...bundle,
    type: 'bundle',
    visible: bundle.visible
      ? bundle.visible
      : !Boolean(hiddenBundles.includes(bundle.sku)),
  }));
};

const getSeparateSubscriptionsAndBundles = (subscriptionsOrBundles: Array<ISubscription | IBundle>) => {
  const bundles = subscriptionsOrBundles.filter(isBundle) as IBundle[];
  const subscriptions = subscriptionsOrBundles.filter(
    (subOrBundle) => !isBundle(subOrBundle),
  ) as ISubscription[];

  return { bundles, subscriptions };
};

export default function TicketSubscriptionBundleGateSettings({
  accessCodeEnabled,
  accessGateStateChanges,
  setAccessCodeEnabled,
  setAccessGateStateChanges,
  setHasGateError,
  showAdvancedOptions,
  setVisibilityError,
  handleChangeGateClick,
  setIsGateOnFinalStep,
}: ITicketSubscriptionBundleGateSettings) {
  const hiddenEntitlements =
    accessGateStateChanges.gate?.hiddenEntitlements || [];
  const hiddenBundles =
    accessGateStateChanges.gate?.hiddenBundles || [];

  const subscriptions = parseSubscriptionData(
    accessGateStateChanges.gate?.subscriptions || [],
    hiddenEntitlements,
  );
  const bundles = parseBundleData(accessGateStateChanges.gate?.bundles || [], hiddenBundles);
  const [hiddenEntitlementsData, setHiddenEntitlementsData] = useState(hiddenEntitlements);
  const [hiddenBundlesData, setHiddenBundlesData] = useState(hiddenBundles);
  const [subscriptionAndBundleData, setSubscriptionAndBundleData] = useState<Array<Item>>([
    ...subscriptions,
    ...bundles,
  ]);

  const columns: Array<IColumn> = [
    {
      headerKey: 'ADMIN_LABEL_NAME',
      accessor: NAME_STRING,
      id: NAME_STRING,
      title: NAME_STRING,
      testId: 'name',
    },
    {
      headerKey: 'ADMIN_LABEL_SKU',
      accessor: SKU_STRING,
      id: SKU_STRING,
      title: SKU_STRING,
      testId: 'sku',
    },
    {
      headerKey: 'ADMIN_LABEL_PRICE',
      accessor: PRICES_STRING,
      id: PRICES_STRING,
      title: PRICES_STRING,
      testId: 'price',
      transformValue: (prices: any) => findDefaultPrice(prices),
    },
    {
      headerKey: 'ADMIN_LABEL_TYPE',
      accessor: TYPE_STRING,
      id: TYPE_STRING,
      testId: 'type',
      title: TYPE_STRING,
    },
  ];
  const [columnsData, setColumnsData] = useState(columns);
  const { t } = useAdminTranslation();
  const dispatch = useDispatch();
  const [isAddingSubscription, setAddingSubscription] = useState(false);
  const isAccessCodeFeatureEnabled = useSelector(
    (state: IState) => isFeatureEnabled(state, Feature.ACCESS_CODE),
  );
  const planCanHoldFreeEvents = useSelector(getPlanFeatures)?.freeEvents;

  const isSubscriptionHidden = (subscriptionSku: string) =>
    hiddenEntitlementsData.includes(subscriptionSku) && true;

  const isBundleHidden = (bundleSku: string) => hiddenBundlesData.includes(bundleSku);

  /**
   * Pricing V2: As part of plan limitations, only custom and legacy plans can have access code gates enabled.
   */
  const handleToggleAccessCode = () => {
    if (!planCanHoldFreeEvents) {
      dispatch(
        showModal({
          kind: ModalKinds.upgradePlan,
          data: {
            planWarningMessage: 'ADMIN_UPGRADE_PLAN_LANDING_ACCESSCODE_GATE_ERROR',
            preSelectedPlan: 'custom',
          },
        }),
      );
      return;
    }
    setAccessCodeEnabled(!accessCodeEnabled);
  };

  const updateAccessGateSubscriptionState = (subsOrBundlesToUpdate: Array<Item>) => {
    const { bundles: separatedBundles, subscriptions: separatedSubs } = getSeparateSubscriptionsAndBundles(subsOrBundlesToUpdate);
    setAccessGateStateChanges({
      ...accessGateStateChanges,
      gate: {
        ...accessGateStateChanges.gate,
        subscriptions: [...separatedSubs],
        bundles: [...separatedBundles],
      },
    });
  };

  const onSortedChange: NonNullable<IMediaLibraryTableProps['onSortedChange']> = ([{ id }]) => {
    if (!id) return;
    const newColumnsData = [...columnsData];
    let currentSortDirection: undefined | string;
    // tslint:disable-next-line
    for (
      let columnIndex = 0;
      columnIndex < newColumnsData.length;
      columnIndex++
    ) {
      const column = newColumnsData[columnIndex];

      if (column.id === id) {
        currentSortDirection = newColumnsData[columnIndex].sort;

        switch (currentSortDirection) {
          case ASC_KEY:
            currentSortDirection = DESC_KEY;
            break;
          case DESC_KEY:
            currentSortDirection = ASC_KEY;
            break;
          default:
            currentSortDirection = ASC_KEY;
        }
        newColumnsData[columnIndex].sort = currentSortDirection;
      } else {
        newColumnsData[columnIndex].sort = undefined;
      }
    }
    const sortedSubscriptionsAndBundles: Array<ISubscription | IBundle> = [...subscriptionAndBundleData];

    sortedSubscriptionsAndBundles.sort((a: any, b: any) =>
      currentSortDirection === ASC_KEY
        ? compareToSort(a[id], b[id])
        : compareToSort(b[id], a[id]),
    );
    setSubscriptionAndBundleData(sortedSubscriptionsAndBundles);
    setColumnsData(newColumnsData);
  };

  const onDeleteClick = (subscriptionIndex: number) => {
    const newSubscriptionAndBundleData = [...subscriptionAndBundleData];
    newSubscriptionAndBundleData.splice(subscriptionIndex, 1);
    updateAccessGateSubscriptionState(newSubscriptionAndBundleData);
    setSubscriptionAndBundleData(newSubscriptionAndBundleData);
  };

  const onVisibilityClick = (subscriptionIndex: number) => {
    const subscriptionOrBundle = subscriptionAndBundleData[subscriptionIndex];
    const newVisibilityStatus = !subscriptionOrBundle.visible;
    const newSubscriptionAndBundleData = subscriptionAndBundleData.map((subOrBundle) => {
      if (subOrBundle.sku === subscriptionOrBundle.sku) {
        const newSubscriptionOrBundleItemStatus = {
          ...subOrBundle,
          visible: newVisibilityStatus,
        };
        return newSubscriptionOrBundleItemStatus;
      } else {
        return subOrBundle;
      }
    });
    const newHiddenEntitlementsData = [...hiddenEntitlementsData];
    const newHiddenBundlesData = [...hiddenBundlesData];
    if (!newVisibilityStatus) {
      if (isBundle(subscriptionOrBundle)) {
        newHiddenBundlesData.push(subscriptionOrBundle.sku);
      } else {
        newHiddenEntitlementsData.push(subscriptionOrBundle.sku);
      }
    } else {
      if (isBundle(subscriptionOrBundle)) {
        const indexToRemove = newHiddenBundlesData.indexOf(subscriptionOrBundle.sku);
        newHiddenBundlesData.splice(indexToRemove, 1);
      } else {
        const indexToRemove = newHiddenEntitlementsData.indexOf(subscriptionOrBundle.sku);
        newHiddenEntitlementsData.splice(indexToRemove, 1);
      }
    }
    setHiddenEntitlementsData(newHiddenEntitlementsData);
    setHiddenBundlesData(newHiddenBundlesData);
    setSubscriptionAndBundleData(newSubscriptionAndBundleData);

    const { bundles: separatedBundles, subscriptions: separatedSubs } = getSeparateSubscriptionsAndBundles(newSubscriptionAndBundleData);
    setAccessGateStateChanges({
      ...accessGateStateChanges,
      gate: {
        ...accessGateStateChanges.gate,
        subscriptions: [...separatedSubs],
        bundles: [...separatedBundles],
        hiddenEntitlements: [...newHiddenEntitlementsData],
        hiddenBundles: [...newHiddenBundlesData],
      },
    });
  };

  const handleSetAddSubscription = () => {
    setAddingSubscription(true);
  };

  const handleCloseAddSubscriptionModal = () => {
    setAddingSubscription(false);
  };

  const handleSelectItem = (selectedItem: Item) => {
    const newSubscriptionAndBundleData = [...subscriptionAndBundleData];
    const isSelectedItemBundle = isBundle(selectedItem);
    const newItem: Item = {
      ...selectedItem,
    };

    if (isSelectedItemBundle) {
      newItem.type = 'bundle';
      newItem.visible = !isBundleHidden(newItem.sku);
    } else {
      const item = newItem as ISubscription;
      newItem.type = getTypeValue(item.recurrence || '');
      newItem.visible = !isSubscriptionHidden(newItem.sku);
    }

    newSubscriptionAndBundleData.push(newItem);
    updateAccessGateSubscriptionState(newSubscriptionAndBundleData);
    setSubscriptionAndBundleData(newSubscriptionAndBundleData);
    setAddingSubscription(false);
  };

  useEffect(() => {
    const newSubscriptionsAndBundleData = [...subscriptionAndBundleData];
    if (newSubscriptionsAndBundleData.length === 0) {
      setHasGateError(true);
    } else {
      const hastAtLeastOneVisible = newSubscriptionsAndBundleData.some(({ visible }) => visible);
      if (hastAtLeastOneVisible) {
        setVisibilityError(false);
        setHasGateError(false);
      } else {
        setVisibilityError(true);
        setHasGateError(true);
      }
    }

    return () => {
      setHasGateError(false);
      setVisibilityError(false);
    };
  }, [hash(subscriptionAndBundleData)]);

  useEffect(() => {
    if (accessCodeEnabled) {
      setAccessGateStateChanges({
        ...accessGateStateChanges,
        kind: GateKind.ACCESSCODE,
      });
    } else {
      setAccessGateStateChanges({
        ...accessGateStateChanges,
        kind: GateKind.SUBSCRIPTION,
      });
    }
  }, [accessCodeEnabled]);

  useEffect(() => {
    setIsGateOnFinalStep(true);
  }, []);

  const handleFilterSubsAndTickets = useCallback(
    (subs: ISubscription[]) => {
      return subs.filter((sub) =>
        subscriptions.every((item) => item._id! !== sub._id),
      );
    },
    [subscriptions],
  );

  return (
    <TicketSubscriptionBundleGateSettingsWrapper>
      <AccessControlGateSettingsHeader
        gateText="ACCESS_CONTROL_SUBSCRIPTION_TITLE"
        handleChangeGateClick={handleChangeGateClick}
        iconName="accessControlTicketSubscription"
      />
      {showAdvancedOptions && (
        <AdvancedOptionsWrapper>
          <AccessCodeText>
            <TranslatedText stringKey="ACCESS_CONTROL_ACCESS_CODE_ONLY" />
          </AccessCodeText>
          <TooltipInfoIcon
            tooltip={
              parse(
                t('ACCESS_CONTROL_ACCESS_CODE_TOOLTIP'),
              ) as string
            }
          />
          <ToggleSwitch
            data-testid="accessCodeToggle"
            checked={accessCodeEnabled}
            onChange={handleToggleAccessCode}
          />
        </AdvancedOptionsWrapper>
      )}
      {subscriptionAndBundleData.length > 0 ? (
        <MediaLibraryTable
          columns={columnsData}
          data={subscriptionAndBundleData}
          onSortedChange={onSortedChange}
          onDeleteClick={onDeleteClick}
          onVisibilityClick={onVisibilityClick}
        />
      ) : (
        <NoSubscriptionsText>
          <TranslatedText stringKey="ACCESS_CONTROL_NO_TICKET_BUNDLE_OR_SUBSCRIPTION" />
        </NoSubscriptionsText>
      )}
      <WideButton
        data-testid="addTicketOrSunscriptionButton"
        onClick={handleSetAddSubscription}
      >
        <TranslatedText stringKey="ADMIN_LABEL_ADD_TICKET_BUNDLE_OR_SUBSCRIPTION" />
      </WideButton>
      {isAddingSubscription && (
        <SubscriptionsLibraryModal
          formatData={handleFilterSubsAndTickets}
          disableKeyClick={!isAccessCodeFeatureEnabled}
          disableSelecting={false}
          onClose={handleCloseAddSubscriptionModal}
          onSelectItem={handleSelectItem}
        />
      )}
    </TicketSubscriptionBundleGateSettingsWrapper>
  );
}
