import React, { useState, useEffect, memo } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'hooks';
import { getSaved, updateSaved, getDeveloperSetting, updateDeveloperSetting } from 'services/developer';
import Divider from 'components/admin2/ui/Divider';
import hash from 'json-stable-stringify';
import AdminBarTabHeader, { AdminBarTab } from 'components/admin2/ui/AdminBarTabHeader';
import isEqual from 'lodash/isEqual';
import ApiKeyAccess from './ApiKeyAccess';
import Webhooks from './Webhooks';
import CodeInjectionTab from './CodeInjection';
import { IDeveloperSetting } from 'services/developer/models';
import { DEVELOPER_TAB_ID } from 'global-ids';

const DeveloperTab = () => {
  const dispatch = useDispatch();
  const isSaved = useSelector(getSaved);
  const currentDeveloperSetting = useSelector(getDeveloperSetting, isEqual);
  const {
    apiKey: { data: currentApiKey },
    codeInjection: currentCodeInjection,
    webhooks: { data: currentWebhooks },
  } = currentDeveloperSetting;
  const [newDeveloperSetting, setNewDeveloperSetting] = useState<IDeveloperSetting>({
    apiKey: currentApiKey,
    codeInjection: currentCodeInjection,
    webhooks: currentWebhooks,
  });
  const overSizeLimit = !!newDeveloperSetting.codeInjection?.overSizeLimit;

  const {
    codeInjection: newCodeInjection,
    webhooks: newWebhooks,
  } = newDeveloperSetting;
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  useEffect(() => {
    setNewDeveloperSetting({
      ...newDeveloperSetting,
      webhooks: currentWebhooks,
    });
  }, [hash(currentWebhooks)]);

  useEffect(() => {
    dispatch(updateSaved(false));
  }, [hash(newCodeInjection)]);

  const handleSave = () => {
    dispatch(updateDeveloperSetting(newDeveloperSetting));
    setHasUnsavedChanges(false);
  };

  useEffect(() => {
    const webhooksChanged = !isEqual(currentWebhooks, newWebhooks);
    const webhookUrlChanged = !isEqual(currentWebhooks.webhookUrl, newWebhooks.webhookUrl);
    const shouldAutoSave = webhooksChanged && !webhookUrlChanged && Boolean(newWebhooks.webhookUrl);
    if (shouldAutoSave) {
      handleSave();
    }

    const codeInjectionChanged = !isEqual(currentCodeInjection, newCodeInjection);
    setHasUnsavedChanges((webhookUrlChanged && Boolean(newWebhooks.webhookUrl)) || (codeInjectionChanged && !overSizeLimit)); // eslint-disable-line max-len
  }, [hash(newDeveloperSetting)]);

  return (
    <AdminBarTab id={DEVELOPER_TAB_ID} data-testid={DEVELOPER_TAB_ID}>
      <AdminBarTabHeader
        hasUnsavedChanges={hasUnsavedChanges}
        headerKey="ADMIN_LABEL_SETTINGS"
        onSave={handleSave}
        saved={isSaved}
        subHeaderKey="ADMIN_LABEL_DEVELOPER_SUBHEADER"
        subtitleKey="ADMIN_LABEL_DEVELOPER_TITLE"
      />
      <CodeInjectionTab
        newDeveloperSetting={newDeveloperSetting}
        setNewDeveloperSetting={setNewDeveloperSetting}
      />
      <Divider />
      <ApiKeyAccess />
      <Divider />
      <Webhooks
        newDeveloperSetting={newDeveloperSetting}
        setNewDeveloperSetting={setNewDeveloperSetting}
      />
    </AdminBarTab>
  );
};

export default memo(DeveloperTab, (prevProps, nextProps) => isEqual(prevProps, nextProps));
