import React, { useCallback, useEffect, useState } from 'react';
import { INVITE_SERVICE_BASE_URL } from 'config';
import axios from 'axios';
import { useDispatch, useSelector, useLegacyCollection, useRole } from 'hooks';
import usePagesV3Collection from 'hooks/use-pages-v3-collection';
import { getSiteId } from 'services/app';
import { getPendingInvites } from 'services/invite';
import hash from 'json-stable-stringify';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AdminBarTabHeader from 'components/admin2/ui/AdminBarTabHeader';
import InviteCard from 'components/admin2/ui/InviteCard';
import JsonDebug from 'components/dev/JsonDebug';
import BoxTagDropdown from 'components/admin2/ui/BoxTagDropdown';
import Checkbox from 'components/admin2/ui/Checkbox';
import Radio from 'components/admin2/ui/Radio';
import {
  TAB_WIDTH_MD,
  ADMIN_TEXT_300,
  ADMIN_TEXT_100,
} from 'style/constants';
import {
  ADMIN_BAR_TAB,
} from 'style/mixins';
import { showAdminErrorKey } from 'services/modals';
import { isUserAdmin, getPrimaryToken } from 'services/auth';
import { ADMIN_TEXT_BODY_XS_REGULAR, ADMIN_TEXT_LABEL_M_BOLD } from 'style/design-system/textStyles';
import { useAdminTranslation } from 'hooks/use-translation';
import { getActiveSiteFeatures } from 'services/app/selectors/common';
import { Feature } from 'services/feature-gate';

const propTypes = {
  handleTabBack: PropTypes.func.isRequired,
  user: PropTypes.shape({
    _id: PropTypes.string,
    targetRoles: PropTypes.arrayOf(
      PropTypes.shape({
        scope: PropTypes.string,
        siteId: PropTypes.string,
        write: PropTypes.bool,
      }),
    ),
  }),
};

const defaultProps = {
  user: {},
};

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const Container = styled.div`
  ${ADMIN_TEXT_BODY_XS_REGULAR};
  color: ${ADMIN_TEXT_100};
  ${ADMIN_BAR_TAB}
  padding-top: 23px;
  display: flex;
  flex-flow: column nowrap;
  width: ${TAB_WIDTH_MD};
`;

const ChooseRole = styled.div`
  ${ADMIN_TEXT_LABEL_M_BOLD}
  color: ${ADMIN_TEXT_300};
  text-transform: uppercase;
  padding: 30px 0 12px 0;
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 22px;
`;

const StyledInvite = styled(InviteCard)`
  background: none;
  border: none !important;
  padding: 0;
`;

const RoleContainer = styled.div`
  padding: 0 7px;
`;

const removeScopes = (roles, scopes) => roles.filter(r => !scopes.includes(r.scope));
const ConfigureInviteTab = ({ handleTabBack, user }) => {
  const { t } = useAdminTranslation();
  const dispatch = useDispatch();
  const siteId = useSelector(getSiteId);
  const token = useSelector(getPrimaryToken);
  const loggedInUserIsSiteAdmin = useSelector(isUserAdmin);
  const features = useSelector(getActiveSiteFeatures);
  const pagesHook = features[Feature.PAGES_V3] ? usePagesV3Collection : useLegacyCollection;
  const [pageData] = pagesHook({ collection: 'pages' });

  const [hasChanges, setHasChanges] = useState(false);
  const [saved, setSaved] = useState(false);
  const [editingRoles, setEditingRoles] = useState(user.targetRoles);
  const [siteAdminRole, toggleSiteAdminRole] = useRole(editingRoles, '*');
  const [chatModRole, toggleChatModRole, setChatModPageIds] = useRole(editingRoles, 'Chat_Moderator');
  const [
    allPagesSelected,
    setAllPagesSelected,
  ] = useState(!chatModRole || chatModRole.pageId.length === 0);
  const [specificPageSelected, setSpecificPageSelected] = useState(!allPagesSelected);

  useEffect(() => {
    const otherRoles = removeScopes(editingRoles, ['Chat_Moderator', '*']);
    const newRoles = [siteAdminRole, chatModRole, ...otherRoles].filter(i => i);
    setEditingRoles(newRoles);
    const deepEq = hash(newRoles) === hash(user.targetRoles);
    setHasChanges(!deepEq);
    if (saved) {
      setSaved(false);
    }
  }, [siteAdminRole, chatModRole]);

  const onAllPageChange = (e) => {
    setChatModPageIds([]);
    setSpecificPageSelected(!e.target.checked);
    setAllPagesSelected(e.target.checked);
  };

  const onSpecificPageChange = (e) => {
    setChatModPageIds([]);
    setAllPagesSelected(!e.target.checked);
    setSpecificPageSelected(e.target.checked);
  };

  const handleToggleSiteAdminRole = () => {
    toggleSiteAdminRole();
    if (chatModRole) {
      toggleChatModRole();
    }
  };

  const updateUserRoles = useCallback(() => {
    setHasChanges(false);
    axios({
      data: {
        targetRoles: editingRoles,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        'x-maestro-client-id': siteId,
      },
      method: 'PUT',
      url: `${INVITE_SERVICE_BASE_URL}/${user._id}`,
    }).then((res) => {
      const updatedRoles = get(res, 'data.targetRoles', []);
      setEditingRoles(updatedRoles);
      dispatch(getPendingInvites());
      setSaved(true);
    }).catch(() => {
      setEditingRoles(user.targetRoles);
      dispatch(showAdminErrorKey('ADMIN_ERROR_GENERIC'));
    });
  }, [
    dispatch,
    chatModRole,
    editingRoles,
    siteAdminRole,
    setEditingRoles,
    setHasChanges,
  ]);

  return (
    <Wrapper>
      <AdminBarTabHeader
        hasUnsavedChanges={hasChanges}
        headerKey="ADMIN_LABEL_MANAGE"
        history={[
          {
            nameKey: 'ADMIN_LABEL_ADMINS',
            navigate: handleTabBack,
          },
          {
            nameKey: 'ADMIN_LABEL_INVITE_SETTINGS',
          },
        ]}
        onBack={handleTabBack}
        onSave={updateUserRoles}
        saved={saved}
        subHeaderKey="ADMIN_LABEL_INVITE_SETTINGS"
      />
      <Container>
        <StyledInvite invite={user} />
        <RoleContainer>
          <ChooseRole>Roles</ChooseRole>
          <Checkbox
            checked={!!siteAdminRole}
            descriptionKey="ADMIN_ROLE_SITE_ADMIN_DESC"
            label={t('ADMIN_INVITE_TAB_SITE_ADMIN')}
            onChange={handleToggleSiteAdminRole}
          />
          <Checkbox
            checked={!!(siteAdminRole || chatModRole)}
            descriptionKey="ADMIN_ROLE_CHAT_ADMIN_DESC"
            disabled={!loggedInUserIsSiteAdmin || siteAdminRole}
            label={t('ADMIN_INVITE_TAB_MODERATOR')}
            onChange={toggleChatModRole}
          />
          {chatModRole && !siteAdminRole && (
            <>
              <RadioWrapper>
                <Radio
                  checked={allPagesSelected}
                  labelKey="ADMIN_ROLE_CHAT_ADMIN_ENTIRE_SITE"
                  onChange={onAllPageChange}
                />
                <Radio
                  checked={specificPageSelected}
                  labelKey="ADMIN_ROLE_CHAT_ADMIN_SPECIFIC_ACCESS"
                  onChange={onSpecificPageChange}
                />
              </RadioWrapper>
              {!allPagesSelected && (
                <BoxTagDropdown
                  getOptionLabel={option => get(option, 'data.name', option.slug)}
                  getOptionValue={option => option._id}
                  isSearchable
                  onChange={setChatModPageIds}
                  options={pageData}
                  placeholder={t('ADMIN_PLACEHOLDER_SEARCH_FOR_A_CHANNEL')}
                  valueKey="_id"
                  values={get(chatModRole, 'pageId', [])}
                />
              )}
            </>
          )}
        </RoleContainer>
      </Container>
      <JsonDebug value={editingRoles} />
    </Wrapper>
  );
};

ConfigureInviteTab.propTypes = propTypes;
ConfigureInviteTab.defaultProps = defaultProps;

export default ConfigureInviteTab;
